import useSettings from "hooks/useSettings";
import { Stack, Typography } from "@material-ui/core";
import { fDate, fTime } from "utils/formatTime";

type ContactTableDateTimeProps = {
  value: Date | string | number;
  forceHeLocale?: boolean;
};

export function ContactTableDateTime({
  value,
  forceHeLocale
}: ContactTableDateTimeProps): JSX.Element {
  const { themeDirection } = useSettings();

  const isRTL = forceHeLocale ? true : themeDirection === "rtl";

  return (
    <Stack>
      <Typography variant="body2">{fDate(value, isRTL)}</Typography>
      <Typography
        sx={{
          fontSize: 12,
          color: "text.secondary"
        }}
        variant="body2"
      >
        {fTime(value, isRTL)}
      </Typography>
    </Stack>
  );
}

import Button from "@material-ui/core/Button";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import * as React from "react";
import useLocales from "../../../hooks/useLocales";
import { useModal } from "mui-modal-provider";
import { sleep } from "utils/Haim/utils";

type Props = DialogProps & {
  title: string;
  description: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  showCancel?: boolean;
  showConfirm?: boolean;
  onClose?: () => void;
};

export const ConfirmationDialog: React.FC<Props> = ({
  title,
  description,
  showCancel,
  showConfirm,
  onCancel,
  onConfirm,
  onClose,
  ...props
}) => {
  const { translate } = useLocales();

  return (
    <Dialog {...props} maxWidth="xs" fullWidth={true} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {showCancel && (
          <Button onClick={onCancel} color="primary">
            {translate("general.cancel")}
          </Button>
        )}
        {showConfirm && (
          <Button onClick={onConfirm} color="primary">
            {translate("general.approve")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export enum ModalAsyncResult {
  confirm,
  cancel,
  closed
}

// Need the instance because this function is not a react component
export async function showModalAsync({
  showModalInstance,
  title,
  description,
  showConfirm = false,
  showCancel = false
}): Promise<ModalAsyncResult> {
  var result: ModalAsyncResult = null;
  var modal = showModalInstance(ConfirmationDialog, {
    title,
    description,
    showConfirm,
    showCancel,
    onConfirm: () => {
      modal.hide();
      result = ModalAsyncResult.confirm;
    },
    onCancel: () => {
      modal.hide();
      result = ModalAsyncResult.cancel;
    },
    onClose: () => {
      modal.hide();
      result = ModalAsyncResult.closed;
    }
  });

  while (result === null) {
    await sleep(500);
  }

  return result;
}

import { createContext } from "react";
import { orgAdminStatsResponseSchema, WebotServerContextProps } from "../@types/webotServer";
import useAuth from "../hooks/useAuth";
import useFirestore from "hooks/useFirestore";

import axios from "axios";
import { WEBOT_SERVER_ENDPOINT } from "logic/Consts";
import type { MarkNoSpamContactsResponse } from "../@types/webotServer";
import type { ReactNode } from "react";

const WebotServerContext = createContext({} as WebotServerContextProps);

type WebotServerProviderProps = {
  children: ReactNode;
};

function WebotServerProvider({ children }: WebotServerProviderProps) {
  const { myApiToken } = useFirestore();
  const { user } = useAuth();

  const baseUrl = `${WEBOT_SERVER_ENDPOINT}/api/v1`;

  const sendMessageUrl = `${baseUrl}/sendMessage`;

  const sendWhatsAppMessage = async (
    fromPhoneNumber: string,
    toPhoneNumber: string,
    message: string,
    mediaLink: string = "",
    customFileName: string = ""
  ) => {
    try {
      const response = await axios.post(sendMessageUrl, {
        fromPhoneNumber,
        toPhoneNumber,
        message,
        mediaLink,
        customFileName,
        token: myApiToken
      });
      if (response.status !== 200 || response.data?.error) {
        console.error("Error sending message:", response);
        return {
          error: response.data?.error ?? "unknown error"
        };
      }

      return {
        success: true
      };
    } catch (error) {
      console.error("Caught error sending message:", error, error.response?.data);
      return {
        error
      };
    }
  };

  const checkExtensionStatus = async () => {
    const checkStatusUrl = `${baseUrl}/checkStatus`;
    console.log("Checking App Status..", checkStatusUrl, myApiToken);
    let errorData;
    const response: any = await axios
      .post(checkStatusUrl, {
        token: myApiToken
      })
      .catch((error) => {
        console.log("Error getting status:", error);
        errorData = "error status: " + error.response?.status + ", " + error.response?.data.error;
      });
    return { response: response, error: errorData };
  };

  const getOrgAdminStats = async (dateFrom: Date, organizationId?: string) => {
    const url = `${baseUrl}/get-data/org-admin-stats`;
    const userIdToken = await user?.getIdToken();

    console.log("userIdToken:", userIdToken);

    const results = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        dateFrom: dateFrom,
        organizationId: organizationId
      }),
      headers: {
        Authorization: userIdToken as string,
        "Content-Type": "application/json"
      }
    });

    const stats = (await results.json()) as orgAdminStatsResponseSchema;

    return stats;
  };

  const markNoSpamContacts = async (): Promise<MarkNoSpamContactsResponse> => {
    const url = `${baseUrl}/contacts/mark-no-spam`;

    const userIdToken = await user?.getIdToken();

    const results = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: userIdToken as string,
        "Content-Type": "application/json"
      }
    });

    const data = await results.json();

    return data;
  };

  return (
    <WebotServerContext.Provider
      value={{
        sendWhatsAppMessage,
        checkExtensionStatus,
        getOrgAdminStats,
        markNoSpamContacts
      }}
    >
      {children}
    </WebotServerContext.Provider>
  );
}

export { WebotServerProvider, WebotServerContext };

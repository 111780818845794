// material
import { alpha, Theme, useTheme, styled } from "@material-ui/core/styles";
import { BoxProps } from "@material-ui/core";
// @types
import { ColorSchema } from "../@types/theme";
import { Close } from "@material-ui/icons";

// ----------------------------------------------------------------------

export type LabelColor =
  | "default"
  | "primary"
  | "secondary"
  | "info"
  | "success"
  | "warning"
  | "error";

type LabelVariant = "filled" | "outlined" | "ghost";

export const getLabelColorForString = (str: string): LabelColor => {
  const hash = str.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0);
  return hash % 6 === 0
    ? "default"
    : hash % 6 === 1
    ? "primary"
    : hash % 6 === 2
    ? "secondary"
    : hash % 6 === 3
    ? "info"
    : hash % 6 === 4
    ? "success"
    : "warning";
};

const RootStyle = styled("span")(
  ({
    theme,
    styleProps
  }: {
    theme: Theme;
    styleProps: {
      color: LabelColor;
      variant: LabelVariant;
    };
  }) => {
    const isLight = theme.palette.mode === "light";
    const { color, variant } = styleProps;

    const styleFilled = (color: ColorSchema) => ({
      color: theme.palette[color].contrastText,
      backgroundColor: theme.palette[color].main
    });

    const styleOutlined = (color: ColorSchema) => ({
      color: theme.palette[color].main,
      backgroundColor: "transparent",
      border: `1px solid ${theme.palette[color].main}`
    });

    const styleGhost = (color: ColorSchema) => ({
      color: theme.palette[color][isLight ? "dark" : "light"],
      backgroundColor: alpha(theme.palette[color].main, 0.16)
    });

    return {
      height: 22,
      minWidth: 22,
      lineHeight: 0,
      borderRadius: 8,
      cursor: "default",
      alignItems: "center",
      whiteSpace: "nowrap",
      display: "inline-flex",
      justifyContent: "center",
      padding: theme.spacing(0, 1),
      color: theme.palette.grey[800],
      fontSize: theme.typography.pxToRem(12),
      fontFamily: theme.typography.fontFamily,
      backgroundColor: theme.palette.grey[300],
      fontWeight: theme.typography.fontWeightBold,

      ...(color !== "default"
        ? {
            ...(variant === "filled" && { ...styleFilled(color) }),
            ...(variant === "outlined" && { ...styleOutlined(color) }),
            ...(variant === "ghost" && { ...styleGhost(color) })
          }
        : {
            ...(variant === "outlined" && {
              backgroundColor: "transparent",
              color: theme.palette.text.primary,
              border: `1px solid ${theme.palette.grey[500_32]}`
            }),
            ...(variant === "ghost" && {
              color: isLight ? theme.palette.text.secondary : theme.palette.common.white,
              backgroundColor: theme.palette.grey[500_16]
            })
          })
    };
  }
);

// ----------------------------------------------------------------------

interface LabelProps extends BoxProps {
  color?: LabelColor;
  variant?: LabelVariant;
  onDelete?: React.MouseEventHandler<SVGSVGElement>;
}

export default function Label({
  color = "default",
  variant = "ghost",
  onDelete,
  children,
  sx
}: LabelProps) {
  const theme = useTheme();

  return (
    <RootStyle styleProps={{ color, variant }} sx={sx} theme={theme}>
      {children}
      {onDelete && (
        <Close
          sx={{ width: 15, height: 15 }}
          color={"primary"}
          style={{ cursor: "pointer" }}
          onClick={onDelete}
        />
      )}
    </RootStyle>
  );
}

// @ts-nocheck
import useAuth from "hooks/useAuth";
import useFirestore from "hooks/useFirestore";
import useSettings from "hooks/useSettings";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { PATH_DASHBOARD, PATH_PUBLIC } from "routes/paths";

// ----------------------------------------------------------------------

const pagesPrefixWithoutChat = [
  PATH_PUBLIC.listings,
  PATH_PUBLIC.myListings,
  PATH_DASHBOARD.general.listingsMatch
];

// Update Crisp.chat with user session data - https://docs.crisp.chat/guides/chatbox-sdks/web-sdk/dollar-crisp/
export default function CrispSettings() {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const { userSettings } = useFirestore();
  const { themeDirection } = useSettings();

  $crisp.push(["safe", true]);

  useEffect(() => {
    if (!$crisp || !userSettings) {
      return;
    }
    $crisp.push([
      "set",
      "session:data",
      [
        Object.entries(userSettings).map((arr) => [
          arr[0],
          typeof arr[1] === "string" ? arr[1] : JSON.stringify(arr[1])
        ])
      ]
    ]);

    if (userSettings.ownerPhoneNumber) {
      $crisp.push(["set", "user:phone", [userSettings.ownerPhoneNumber]]);
      $crisp.push([
        "set",
        "session:data",
        ["whatsappLink", `https://wa.me/${parseInt(userSettings.ownerPhoneNumber)}`]
      ]);
    }
  }, [userSettings]);

  useEffect(() => {
    if (!$crisp || !user || !user.email) {
      return;
    }
    $crisp.push(["set", "user:email", [user.email]]);
    $crisp.push(["set", "user:nickname", [user.email.split("@")]]);
    if (user.photoURL) {
      $crisp.push(["set", "user:avatar", [user.photoURL]]);
    }
  }, [user]);

  useEffect(() => {
    if (!$crisp || !pathname) {
      return;
    }

    if (pagesPrefixWithoutChat.some((page) => pathname.startsWith(page))) {
      $crisp.push(["do", "chat:hide"]);
    } else {
      $crisp.push(["do", "chat:show"]);
    }

    $crisp.push(["set", "session:data", ["currentPage", pathname]]);
  }, [pathname]);

  useEffect(() => {
    if (!$crisp || !themeDirection) {
      return;
    }
    $crisp.push(["config", "position:reverse", [themeDirection === "rtl"]]);
  }, [themeDirection]);

  return null;
}

import { Avatar, Box, ListItem, ListItemText, ListItemAvatar } from "@material-ui/core";
import { Autocomplete, TextField } from "@material-ui/core";
import useFirestore from "hooks/useFirestore";
import type { SyntheticEvent } from "react";
import type { Organization } from "../../@types/sharedSchema";

export function OrganizationSwitch(): JSX.Element {
  const { organization, organizations, changeOrganization } = useFirestore();

  const handleOrganizationChange = (_event: SyntheticEvent, newValue: Organization): void => {
    changeOrganization(newValue.id);
  };

  if (!organizations.length) return null;

  return (
    <Box sx={{ px: 2 }}>
      <Autocomplete
        value={organization}
        options={organizations}
        disableClearable
        renderInput={(params) => <TextField {...params} label="Organization" />}
        getOptionLabel={(option) => option.name}
        renderOption={(props, { name, logo }) => {
          return (
            <ListItem {...props}>
              <ListItemAvatar>
                <Avatar src={logo} />
              </ListItemAvatar>
              <ListItemText primary={name} />
            </ListItem>
          );
        }}
        onChange={(e, o) => handleOrganizationChange(e, o as any)}
      />
    </Box>
  );
}

import firebase from "firebase/compat";
import type { ListingType, ListingState, Status, Visibility } from "./unions";
import type { AdditionalUserWithFee } from "./user";
import type { ImagePreview } from "./data";
import type { PriceHistory } from "./new";

export type Listing = {
  id: string;
  fee: null | number;
  name: string;
  city: string;
  rooms: null | number;
  floor: null | number;
  price: number;
  owners: string[];
  status: Status;
  address: string;
  hasBars: boolean;
  pictures: null | ImagePreview[];
  signDate: null | firebase.firestore.Timestamp;
  createdBy: string;
  hasGarden: boolean;
  floorOutOf: null | number;
  hasTerrace: boolean;
  hasStorage: boolean;
  hasParking: boolean;
  visibility: Visibility;
  ownersData: null | AdditionalUserWithFee[];
  createdTime: firebase.firestore.Timestamp;
  updatedTime: firebase.firestore.Timestamp;
  projectName: string;
  buyerOrigin: null | string[];
  exclusivity: null | firebase.firestore.Timestamp;
  hasElevator: boolean;
  cleaningFee: null | number;
  description: string;
  listingType: null | ListingType;
  housingUnit: boolean;
  listingState: null | ListingState;
  priceHistory: null | PriceHistory[];
  evictionDate: null | firebase.firestore.Timestamp;
  neighborhood: string;
  userFavorites: string[];
  protectedSpace: boolean;
  sizeOfGardenM2: null | number;
  sizeOfTerraceM2: null | number;
  buyerOriginData: null | AdditionalUserWithFee[];
  apartmentNumber: null | number;
  sizeOfPropertyM2: null | number;
  feeIncludesTaxes: boolean;
  parkingSpotsCount: null | number;
  buyerAttorneyName: string;
  municipalityTaxes: null | number;
  finalListingPrice: null | number;
  sellerAttorneyName: string;
  hasAirConditioning: boolean;
  addressAreaNickname: string;
  pendingPriceUpdates: boolean;
  dealAdditionDetails: string;
  userDefinedListingId: string;
  createdByOrganization: null | string;
  accessibleForDisabled: boolean;
  buyerAttorneyPhoneNumber: string;
  sellerAttorneyPhoneNumber: string;
};

export const listingConverter: firebase.firestore.FirestoreDataConverter<Listing> = {
  toFirestore(listing: Listing) {
    return listing;
  },
  fromFirestore(snapshot, options) {
    const { id } = snapshot;
    const data = snapshot.data(options);

    return { ...data, id, name: data.owner?.name } as Listing;
  }
};

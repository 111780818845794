/* eslint-disable react-hooks/rules-of-hooks */

import firebase from "firebase/compat";
import { useTranslation } from "react-i18next";
import useSettings from "hooks/useSettings";
import { fNumber } from "../formatNumber";
import { fDate } from "utils/formatTime";
import type { CSSProperties } from "react";
import type { SvgIconComponent } from "@material-ui/icons";
import type { Listing } from "../../@types/listings/listing";
import useLocales from "hooks/useLocales";

export function getFullAddress({
  address,
  neighborhood,
  city
}: Pick<Listing, "address" | "city" | "neighborhood">): string {
  return [address, neighborhood, city].filter(Boolean).join(", ");
}

export function clampLineStyle(lineClamp: number): CSSProperties {
  return {
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitLineClamp: lineClamp,
    WebkitBoxOrient: "vertical"
  };
}

export type PropertyDetailsProps = {
  listing: Listing;
  withOptionalProps?: boolean;
};

export type Details = {
  title: string;
  value: string | number;
  Icon?: SvgIconComponent;
  usingRTL?: boolean;
};

export function createAdditionalPropertyDetailsForRealtor({
  listing
}: PropertyDetailsProps): Details[] {
  const {
    fee,
    cleaningFee,
    exclusivity,
    projectName,
    feeIncludesTaxes,
    municipalityTaxes,
    addressAreaNickname,
    userDefinedListingId
  } = listing;

  const { t: translate } = useTranslation();
  const { currentLang } = useLocales();

  return [
    {
      title: translate("listingsPage.tableColumns.exclusivity"),
      value: exclusivity?.toDate?.()?.toLocaleDateString?.(currentLang.dateLocale) ?? null
    },
    {
      title: translate("listingsPage.tableColumns.fee"),
      value: fee
        ? `${fee}%${
            feeIncludesTaxes ? ` (${translate("listingsPage.tableColumns.feeIncludesTaxes")})` : ""
          }`
        : "-"
    },
    {
      title: translate("listingsPage.tableColumns.addressAreaNickname"),
      value: addressAreaNickname ? addressAreaNickname : "-"
    },
    {
      title: translate("listingsPage.tableColumns.cleaningFee"),
      value: numberToCurrency(cleaningFee)
    },
    {
      title: translate("listingsPage.tableColumns.municipalityTaxes"),
      value: numberToCurrency(municipalityTaxes)
    },
    {
      title: translate("listingsPage.tableColumns.projectName"),
      value: projectName ? projectName : "-"
    },
    {
      title: translate("listingsPage.tableColumns.userDefinedListingId"),
      value: userDefinedListingId ? userDefinedListingId : "-"
    }
  ];
}

export function boolToYesNo(value: boolean): string {
  const { t: translate } = useTranslation();

  return value ? translate("general.yes") : "-";
}

export function numberToCurrency(value: number | null): string {
  return value ? `₪${fNumber(value)}` : "-";
}

export function removeNumberFromAddress(address: string): string {
  return address?.replace(/\d+/g, "").trim();
}

export function getNumberFromAddress(address: string): number | null {
  const num = address?.replace(/\D/g, "");
  const parsed = parseInt(num);
  return isNaN(parsed) ? null : parsed;
}

export function timestampToDate(timestamp: firebase.firestore.Timestamp | null): string {
  const { themeDirection } = useSettings();

  if (!timestamp) return "-";

  const isRTL = themeDirection === "rtl";

  return fDate(timestamp.toDate(), isRTL);
}

// routes
import NavSection from "components/NavSection";
import useFirestore from "hooks/useFirestore";
import { useTranslation } from "react-i18next";
import SvgIconStyle from "../../components/SvgIconStyle";
import { PATH_DASHBOARD } from "../../routes/paths";

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}`} sx={{ width: "100%", height: "100%" }} />
);

const ICONS = {
  blog: getIcon("ic_blog.svg"),
  cart: getIcon("ic_cart.svg"),
  chat: getIcon("ic_chat.svg"),
  mail: getIcon("ic_mail.svg"),
  user: getIcon("ic_user.svg"),
  kanban: getIcon("ic_kanban.svg"),
  banking: getIcon("ic_banking.svg"),
  calendar: getIcon("ic_calendar.svg"),
  ecommerce: getIcon("ic_ecommerce.svg"),
  analytics: getIcon("ic_analytics.svg"),
  dashboard: getIcon("ic_dashboard.svg"),
  booking: getIcon("ic_booking.svg"),
  house: getIcon("house.png"),
  houseSearch: getIcon("house_search.png"),
  phone: getIcon("phone.png"),
  import: getIcon("import.png")
};

type LocalizedSidebarConfigProps = {
  isShow: boolean;
};

export default function LocalizedSidebarConfig({ isShow }: LocalizedSidebarConfigProps) {
  const { userSettings, organization } = useFirestore();

  const { t } = useTranslation();

  const isRealEstateUser = userSettings?.crmType === "realEstate";
  // const hasListingMatchFeatureFlagOnOrganization =
  //   (organization?.featureFlagsV2?.includes("listingsMatch") ||
  //     userSettings?.featureFlagsV2?.includes("listingsMatch")) ??
  //   false;
  const sidebarConfig = [
    {
      subheader: "",
      items: [
        {
          title: t("sidebarPageTitles.general.main"),
          path: PATH_DASHBOARD.general.main,
          icon: ICONS.analytics
        },
        {
          title: t("sidebarPageTitles.general.contacts"),
          path: PATH_DASHBOARD.general.contacts,
          icon: ICONS.user
        },
        {
          title: t("sidebarPageTitles.whatsAppWeb.sendMessages"),
          path: PATH_DASHBOARD.whatsAppWeb.sendMessages,
          icon: ICONS.mail
        },
        {
          title: t("sidebarPageTitles.general.calls"),
          path: PATH_DASHBOARD.general.calls,
          icon: ICONS.phone
        },
        {
          title: t("sidebarPageTitles.autoreplyBotSettings.autoreplyBot"),
          path: PATH_DASHBOARD.autoreplyBotSettings.autoreplyBot,
          icon: ICONS.chat
        },
        {
          title: t("sidebarPageTitles.calendar"),
          path: PATH_DASHBOARD.general.calendar,
          icon: ICONS.calendar
        },
        ...(isRealEstateUser
          ? [
              {
                title: t("sidebarPageTitles.listings"),
                path: PATH_DASHBOARD.general.listings,
                icon: ICONS.house
              },
              {
                title: t("sidebarPageTitles.listingsMatching"),
                path: PATH_DASHBOARD.general.listingsMatch,
                icon: ICONS.houseSearch,
                isNew: true
              }
            ]
          : []),
        {
          title: t("sidebarPageTitles.general.importLeads"),
          path: PATH_DASHBOARD.general.importLeads,
          icon: ICONS.import
        }
      ]
    }
  ];

  return <NavSection navConfig={sidebarConfig} isShow={isShow} />;
}

import { FormEvent, useEffect, useState } from "react";
import {
  Box,
  Card,
  Stack,
  Avatar,
  Button,
  Container,
  Typography,
  CardContent,
  CardActions,
  MobileStepper,
  Tooltip,
  FormControl,
  TextField,
  List
} from "@material-ui/core";
import { Check, KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { doc, updateDoc } from "firebase/firestore";
import useAuth from "hooks/useAuth";
import { usersCollection } from "utils/firestoreConverters";
import { UserRoleInput } from "./UserRoleInput";
import AccountPopover from "layouts/dashboard/AccountPopover";
import { LoadingButton } from "@material-ui/lab";
import OutlinedCard from "../Settings/ProfileCard";
import useFirestore from "hooks/useFirestore";
import { OnboardingStep } from "./../../../@types/firestore";
import { PhoneNumberInput } from "./PhoneNumberInput";
import { useTranslation } from "react-i18next";
import useSettings from "hooks/useSettings";
import { RealEstateRoleInput } from "./RealEstateRoleInput";
import { WebotFeaturesPicker } from "./WebotFeaturesPicker";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@material-ui/core";
import { SoldConfettiAnimation } from "components/_dashboard/listings/table/core/SoldConfettiAnimation";
import { sleep } from "utils/Haim/utils";
import ReactPixel from "react-facebook-pixel";

const businessOnboardingSteps: OnboardingStep[] = ["phoneNumber", "userRolePick", "featuresPicker"];
const realtorOnboardingSteps: OnboardingStep[] = [
  "phoneNumber",
  "userRolePick",
  "realEstateRolePick",
  "featuresPicker"
];

export default function Onboarding() {
  const { user } = useAuth();
  const { userSettings } = useFirestore();
  const { t } = useTranslation();

  const { themeDirection } = useSettings();
  const isRTL = themeDirection === "rtl";

  const [onboardingSteps, setOnboardingSteps] = useState<OnboardingStep[]>(businessOnboardingSteps);

  const [currentStep, setCurrentStep] = useState(
    userSettings?.onboardingStep ? onboardingSteps.indexOf(userSettings?.onboardingStep) : 0
  );
  const [loading, setLoading] = useState(false);
  const [canMoveToNextStep, setCanMoveToNextStep] = useState(true);
  const [confettiOpen, setConfettiOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery((theme as any).breakpoints?.down("sm"));

  // Hide Crisp chat during onboarding
  useEffect(() => {
    // @ts-ignore
    $crisp?.push(["do", "chat:hide"]);
    return () => {
      // @ts-ignore
      $crisp?.push(["do", "chat:show"]);
    };
  }, []);

  useEffect(() => {
    if (!userSettings) return;

    let updatedOnboardingSteps: OnboardingStep[] = [];
    switch (userSettings.crmType) {
      case "generic":
        updatedOnboardingSteps = businessOnboardingSteps;
        break;
      case "realEstate":
        updatedOnboardingSteps = realtorOnboardingSteps;
        break;
    }

    setOnboardingSteps(updatedOnboardingSteps);
    if (userSettings?.onboardingStep) {
      setCurrentStep(updatedOnboardingSteps.indexOf(userSettings?.onboardingStep));
    }
  }, [userSettings]);

  const manageIndex = (type: "prev" | "next") => async () => {
    const nextIndex = currentStep + (type === "prev" ? -1 : 1);
    const nextStep = onboardingSteps[nextIndex];

    setCanMoveToNextStep(true);

    await updateDoc(doc(usersCollection, user?.email), {
      onboardingStep: nextStep
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await manageIndex("next")();
  };

  const handleFinish = async () => {
    setConfettiOpen(true);
    await sleep(500);
    await setLoading(true);
    await logToFacebookPixel();
    await updateDoc(doc(usersCollection, user?.email), {
      onboardingCompleted: true
    });
    await setLoading(false);
  };

  const logToFacebookPixel = async () => {
    try {
      ReactPixel?.track("SubmitApplication", {
        phoneNumber: userSettings?.ownerPhoneNumber,
        email: user?.email
      });
    } catch (error) {
      console.error("Error in ReactPixel", error);
    }
  };

  const onboardingStep = onboardingSteps[currentStep];

  let currentStepComponent: JSX.Element;

  switch (onboardingStep) {
    case "phoneNumber":
      currentStepComponent = (
        <PhoneNumberInput
          onboarding
          onLoadingChange={setLoading}
          setCanMoveToNextStep={setCanMoveToNextStep}
        />
      );
      break;
    case "userRolePick":
    default:
      currentStepComponent = (
        <UserRoleInput
          onboarding
          onLoadingChange={setLoading}
          setCanMoveToNextStep={setCanMoveToNextStep}
        />
      );
      break;
    case "realEstateRolePick":
      currentStepComponent = (
        <RealEstateRoleInput
          onboarding
          onLoadingChange={setLoading}
          setCanMoveToNextStep={setCanMoveToNextStep}
        />
      );
      break;
    case "featuresPicker":
      currentStepComponent = (
        <WebotFeaturesPicker
          onboarding
          onLoadingChange={setLoading}
          setCanMoveToNextStep={setCanMoveToNextStep}
        />
      );
      break;
    case "test":
      break;
  }

  return (
    <Container maxWidth={isMobile ? "sm" : "md"} sx={{ p: 2 }}>
      <SoldConfettiAnimation open={confettiOpen} onConfettiOpen={setConfettiOpen} />
      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 4, px: 1 }}
      >
        <Typography variant="h4">{t("onboarding.welcomeTitle")}</Typography>
        <AccountPopover />
      </Stack>
      <Stack
        direction={isMobile ? "column" : "row"}
        alignItems={isMobile ? "center" : "start"}
        justifyContent="center"
      >
        <Box
          justifyContent="center"
          alignItems="center"
          display="flex"
          sx={{ maxHeight: isMobile ? 200 : 300 }}
        >
          <img
            alt="login"
            style={{ width: "100%", maxHeight: isMobile ? 200 : 300 }}
            src="/static/illustrations/illustration_register.png"
          />
        </Box>
        <Stack
          justifyContent="space-between"
          sx={{ width: "100%", height: "100%", display: "flex" }}
        >
          <CardContent>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                pb: 2
              }}
            >
              {currentStepComponent}
            </Box>
            <MobileStepper
              sx={{ width: "100%", mt: 1 }}
              variant="dots"
              position={isMobile ? "bottom" : "static"}
              steps={onboardingSteps.length}
              activeStep={currentStep}
              backButton={
                currentStep === 0 ? (
                  <Box width={"70px"} />
                ) : (
                  <LoadingButton
                    size="small"
                    startIcon={isRTL ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                    loading={loading}
                    disabled={loading || currentStep === 0}
                    onClick={manageIndex("prev")}
                  >
                    {t("onboarding.backButton")}
                  </LoadingButton>
                )
              }
              nextButton={
                currentStep === onboardingSteps.length - 1 ? (
                  <LoadingButton
                    variant="contained"
                    size="medium"
                    color="success"
                    endIcon={<Check />}
                    loading={loading}
                    disabled={loading || !canMoveToNextStep}
                    onClick={handleFinish}
                  >
                    {t("onboarding.finishButton")}
                  </LoadingButton>
                ) : (
                  <Tooltip
                    title={
                      canMoveToNextStep
                        ? t("onboarding.nextButton")
                        : t("onboarding.nextButtonDisabledTooltip")
                    }
                  >
                    <span>
                      <LoadingButton
                        // type="submit"
                        variant="contained"
                        size="medium"
                        endIcon={isRTL ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                        loading={loading}
                        disabled={loading || !canMoveToNextStep}
                        onClick={manageIndex("next")}
                      >
                        {t("onboarding.nextButton")}
                      </LoadingButton>
                    </span>
                  </Tooltip>
                )
              }
            />
          </CardContent>
        </Stack>
      </Stack>
    </Container>
  );
}

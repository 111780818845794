import * as Yup from "yup";
import { merge } from "lodash";
import { isBefore } from "date-fns";
import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack5";
import trash2Fill from "@iconify/icons-eva/trash-2-fill";
import { useFormik, Form, FormikProvider, Field } from "formik";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import { roundToNearestMinutes, addHours, differenceInHours } from "date-fns";
import { pick } from "lodash";
import {
  Box,
  Stack,
  Button,
  Switch,
  Tooltip,
  TextField,
  IconButton,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Autocomplete,
  createFilterOptions,
  CircularProgress
} from "@material-ui/core";
import {
  LoadingButton,
  LocalizationProvider,
  MobileDateTimePicker,
  MobileDatePicker,
  MobileTimePicker
} from "@material-ui/lab";

import AdapterDateFns from "@material-ui/lab/AdapterDateFns";

import { EventInput } from "@fullcalendar/common";

import ColorSinglePicker from "../../ColorSinglePicker";
import moment from "moment-timezone";
import useFirestore from "hooks/useFirestore";
import { parsePhoneNumber, toInternationalFormat } from "utils/Haim/phoneNumbersUtils";
import { CalendarEvent, CalendarEventAttendee } from "../../../@types/sharedSchema";
import useCalendar from "hooks/useCalendar";
import { useTranslation } from "react-i18next";
import { PhoneNumber } from "libphonenumber-js/types";
import useApp from "hooks/useApp";

// ----------------------------------------------------------------------

const COLOR_OPTIONS = [
  "#00AB55", // theme.palette.primary.main,
  "#1890FF", // theme.palette.info.main,
  "#94D82D", // theme.palette.success.main,
  "#FFC107", // theme.palette.warning.main,
  "#FF4842", // theme.palette.error.main
  "#04297A", // theme.palette.info.darker
  "#7A0C2E" // theme.palette.error.darker
];

const getInitialValues = (event: EventInput | null) => {
  // eslint-disable-next-line no-underscore-dangle
  const _event = {
    title: "",
    description: "",
    textColor: "#1890FF",
    allDay: false,
    start: (event?.start ?? roundToNearestMinutes(new Date(), { nearestTo: 15 })) as Date,
    end: (event?.end ?? addHours(roundToNearestMinutes(new Date(), { nearestTo: 15 }), 1)) as Date,
    attendees: []
  };

  if (event) {
    return merge({}, _event, event);
  }

  return _event;
};

// ----------------------------------------------------------------------

type CalendarEventAttendeeNewFromFilter = {
  phoneNumberObj: PhoneNumber;
  phoneNumberInternational: string;
  displayValue: string;
};

type CalendarFormProps = {
  event: EventInput;
  onUpdate: (CalendarEvent) => Promise<boolean>;
  onDelete: (eventId: string) => Promise<void>;
  onCancel: VoidFunction;
};

export default function CalendarForm({ event, onUpdate, onDelete, onCancel }: CalendarFormProps) {
  const { allContacts, isLoadingContacts } = useApp();

  const { enqueueSnackbar } = useSnackbar();
  const isCreating = !event;
  const { t } = useTranslation();

  const EventSchema = Yup.object().shape({
    title: Yup.string().max(255).required("Title is required"),
    description: Yup.string().max(5000)
  });

  const formik = useFormik({
    initialValues: getInitialValues(event),
    validationSchema: EventSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        const newEvent: CalendarEvent = {
          id: event.id,
          title: values.title,
          description: values.description,
          backgroundColor: values.textColor,
          textColor: values.textColor,
          allDay: values.allDay,
          start: values.start,
          end: values.end,
          attendees: values.attendees
        };
        const result = await onUpdate(newEvent);
        if (result) {
          enqueueSnackbar(t("calendarPage.form.eventUpdatedSuccesfully"), { variant: "success" });
        } else {
          enqueueSnackbar(t("calendarPage.form.eventOperationFailure"), { variant: "error" });
        }
        resetForm();
        onCancel();
        setSubmitting(false);
      } catch (error) {
        console.error(error);
      }
    }
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    validateField
  } = formik;

  const handleDelete = async () => {
    if (!event.id) return;
    try {
      onCancel();
      onDelete(event.id);
      enqueueSnackbar("Delete event success", { variant: "success" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Delete event failed", { variant: "error" });
    }
  };

  const isDateError = isBefore(new Date(values.end), new Date(values.start));

  const getContactStringRepresentation = (
    contact: CalendarEventAttendee & CalendarEventAttendeeNewFromFilter
  ) => {
    if (contact.displayValue) {
      // From filter
      return contact.displayValue;
    }

    const phoneNumberObj =
      contact.phoneNumberObj ?? parsePhoneNumber(contact.phoneNumberInternational);
    const phoneNumberStr =
      phoneNumberObj?.formatNational() ?? contact.phoneNumberInternational ?? contact;
    const contactFullName =
      (contact.firstName ? contact.firstName + " " : "") +
      (contact.lastName ? contact.lastName + " " : "");
    return `${phoneNumberStr} ${contactFullName ? "-" : ""} ${contactFullName}`;
  };

  const contactsCalendarInput: CalendarEventAttendee[] =
    allContacts?.map((c) => ({
      firstName: c.callerFirstName ?? null,
      lastName: c.callerLastName ?? null,
      phoneNumberObj: c._metadata?.callerPhoneNumber,
      phoneNumberInternational: (c._metadata?.callerPhoneNumber?.format("E.164") || c.id) ?? null
    })) ?? [];

  const filterOption = (options, params) => {
    const filterOptionsConfigs = {
      stringify: (option: any) => getContactStringRepresentation(option).replaceAll("-", ""),
      trim: true,
      limit: 20,
      ignoreCase: true
    };
    var filter = createFilterOptions(filterOptionsConfigs);

    const filtered = filter(options, params);

    const { inputValue, getOptionLabel } = params;

    if (isLoadingContacts) {
      filtered.push({
        phoneNumberInternational: null,
        displayValue: t("calendarPage.form.loading")
      });
    } else if (filtered.length === 0) {
      const parsedInputValue = parsePhoneNumber(inputValue);
      if (parsedInputValue) {
        const phoneNumberInternational = parsedInputValue.format("E.164");
        const isExisting = contactsCalendarInput.some(
          (contact) => phoneNumberInternational === contact.phoneNumberInternational
        );
        if (!isExisting) {
          filtered.push({
            phoneNumberObj: parsedInputValue,
            phoneNumberInternational: phoneNumberInternational,
            displayValue: t("calendarPage.form.AttendeePickerNewValue", {
              phoneNumber: parsedInputValue.formatNational()
            })
          } as CalendarEventAttendeeNewFromFilter);
        }
      } else {
        filtered.push({
          phoneNumberInternational: null,
          displayValue: t("calendarPage.form.AttendeePickerInvalidValue", {
            phoneNumber: inputValue
          })
        } as CalendarEventAttendeeNewFromFilter);
      }
    }

    return filtered;
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <DialogContent sx={{ pb: 0, overflowY: "unset" }}>
          <TextField
            fullWidth
            label={t("calendarPage.form.title")}
            {...getFieldProps("title")}
            error={Boolean(touched.title && errors.title)}
            helperText={touched.title && errors.title}
            sx={{ mb: 3 }}
          />

          <Stack sx={{ mb: 2 }} direction="row" gap={2}>
            <MobileDatePicker
              label={t("calendarPage.form.startDay")}
              minDate={new Date()}
              inputFormat="dd/MM/yyyy"
              value={values.start}
              onChange={(date) => {
                setFieldValue("start", date);
                if (!touched.end && values.end) {
                  const newEnd = moment(date)
                    .add(values.end.getTime() - values.start.getTime(), "ms")
                    .toDate();
                  setFieldValue("end", newEnd);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} sx={{ width: 112 }} variant="standard" />
              )}
            />
            <MobileTimePicker
              label={t("calendarPage.form.startTime")}
              inputFormat="HH:mm"
              minutesStep={5}
              value={values.start}
              onChange={(date) => {
                setFieldValue("start", date);
                if (!touched.end && values.end) {
                  const newEnd = moment(date)
                    .add(values.end.getTime() - values.start.getTime(), "ms")
                    .toDate();
                  setFieldValue("end", newEnd);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} sx={{ width: 80 }} variant="standard" />
              )}
              ampm={false}
              ampmInClock={false}
            />
          </Stack>
          <Stack sx={{ mb: 3 }} direction="row" gap={2}>
            <MobileDatePicker
              label={t("calendarPage.form.endDay")}
              minDate={new Date()}
              inputFormat="dd/MM/yyyy"
              value={values.end}
              onChange={(date) => {
                setFieldValue("end", date);
              }}
              renderInput={(params) => (
                <TextField {...params} sx={{ width: 112 }} variant="standard" />
              )}
            />
            <MobileTimePicker
              label={t("calendarPage.form.endTime")}
              inputFormat="HH:mm"
              minutesStep={5}
              value={values.end}
              onChange={(date) => {
                setFieldValue("end", date);
              }}
              renderInput={(params) => (
                <TextField {...params} sx={{ width: 80 }} variant="standard" />
              )}
              ampm={false}
              ampmInClock={false}
            />
          </Stack>

          <Autocomplete
            multiple
            id="tags-outlined"
            options={contactsCalendarInput}
            getOptionLabel={getContactStringRepresentation}
            defaultValue={[]}
            value={values.attendees}
            onChange={(e, value) =>
              setFieldValue(
                "attendees",
                value
                  .map((attendeePickerInput) => {
                    if (typeof attendeePickerInput === "string") {
                      attendeePickerInput = {
                        phoneNumberInternational:
                          parsePhoneNumber(attendeePickerInput)?.format("E.164")
                      };
                    }

                    const fromContacts = contactsCalendarInput.find(
                      (contact) =>
                        contact.phoneNumberInternational ===
                        attendeePickerInput.phoneNumberInternational
                    );
                    return fromContacts ?? attendeePickerInput;
                  })
                  ?.filter((attendeePickerInput) => !!attendeePickerInput.phoneNumberInternational)
                  .map(
                    ({ displayValue, phoneNumberObj, ...relevantAttendeeFieldsBeforeSave }) =>
                      relevantAttendeeFieldsBeforeSave
                  )
              )
            }
            getOptionDisabled={(option) => !option.phoneNumberInternational}
            freeSolo
            autoHighlight
            autoComplete
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            loading={isLoadingContacts}
            filterSelectedOptions
            filterOptions={filterOption}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("calendarPage.form.attendeesLabel")}
                placeholder={t("calendarPage.form.attendeesPlaceholder")}
                sx={{ mb: 2 }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoadingContacts && <CircularProgress color="inherit" size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />

          <TextField
            fullWidth
            multiline
            maxRows={4}
            label={t("calendarPage.form.description")}
            {...getFieldProps("description")}
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
            sx={{ mb: 3 }}
          />

          <ColorSinglePicker {...getFieldProps("textColor")} colors={COLOR_OPTIONS} />
        </DialogContent>

        <DialogActions>
          {!isCreating && (
            <Tooltip title="Delete Event">
              <IconButton onClick={handleDelete}>
                <Icon icon={trash2Fill} width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Button type="button" variant="outlined" color="inherit" onClick={onCancel}>
            {t("general.cancel")}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            disabled={isDateError || Object.keys(errors).length > 0}
            loading={isSubmitting}
            loadingIndicator="Loading..."
          >
            {t("calendarPage.form.approveButton")}
          </LoadingButton>
        </DialogActions>
      </Form>
    </FormikProvider>
  );
}

// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  register: path(ROOTS_AUTH, "/register"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  verify: path(ROOTS_AUTH, "/verify")
};

export const PATH_PAGE = {
  maintenance: "/maintenance",
  page404: "/404",
  page500: "/500",
  components: "/components",
  bot: "/bot"
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    main: path(ROOTS_DASHBOARD, "/main"),
    users: path(ROOTS_DASHBOARD, "/users"),
    contacts: path(ROOTS_DASHBOARD, "/contacts"),
    importLeads: path(ROOTS_DASHBOARD, "/leads/import"),
    calls: path(ROOTS_DASHBOARD, "/calls"),
    unansweredCallsBotResponses: path(ROOTS_DASHBOARD, "/unanswered-calls-bot-responses"),
    calendar: path(ROOTS_DASHBOARD, "/calendar"),
    settings: path(ROOTS_DASHBOARD, "/settings"),
    listings: path(ROOTS_DASHBOARD, "/listings"),
    listingsMatch: path(ROOTS_DASHBOARD, "/listingsMatch")
  },
  whatsAppWeb: {
    status: path(ROOTS_DASHBOARD, "/whatsAppWeb/status"),
    sendMessages: path(ROOTS_DASHBOARD, "/whatsAppWeb/sendMessages")
  },
  autoreplyBotSettings: {
    autoreplyBot: path(ROOTS_DASHBOARD, "/autoreplyBotSettings/bot-autoreply")
  }
};

export const PATH_PUBLIC = {
  listings: "/listings",
  myListings: "/myListings"
};

export const PATH_DOCS = "https://docs-minimals.vercel.app/introduction";

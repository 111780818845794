import { AgGridColumnProps } from "ag-grid-react";
import { ICrmSpecifications } from "../../@types/crmSpecifications";
import moment from "moment-timezone";
import momentDurationFormatSetup from "moment-duration-format";
import "moment/locale/he"; // without this line, language is not imported
import {
  currencyFormatter,
  StringFilterParams,
  NumberFilterParams,
  DateFilterParams,
  NumberComparator
} from "utils/Haim/agGridCellRenderers";
import UploadExplanationGeneric from "components/__haim/ImportLeads/UploadExplanationGeneric";
import { CallSummary } from "../../@types/sharedSchema";
import { getAudioUrlFromCallSummary } from "utils/Haim/utils";
momentDurationFormatSetup(moment as any);

export class GenericCrmSpecifications implements ICrmSpecifications {
  getDefaultDataColumns(): AgGridColumnProps[] {
    return generiCrmColumns;
  }

  getLeadsImportUISection() {
    return <UploadExplanationGeneric />;
  }

  runLeadsImportValidations(parsedRow: any, rawRow: any) {
    return parsedRow;
  }

  getLeadsImportDemoFileName() {
    return "/static/_haim/demoImportFileGeneric.xlsx";
  }
}

export const optedOutOfBulkMessagesPossibleValues = ["", false, true];
export const isInSpamDatabasePossibleValues = ["", false, true];

export const phoneNumberCol: AgGridColumnProps = {
  field: "id",
  headerName: "מספר טלפון",
  cellStyle: { "font-weight": "bold", direction: "ltr", "text-align": "center" },
  filter: true,
  filterParams: StringFilterParams
};

export const metadataParsedCallerPhoneNumberCol: AgGridColumnProps = {
  headerName: "מספר טלפון",
  filter: true,
  filterParams: StringFilterParams,
  cellRenderer: "PhoneNumberCellRenderer",
  // Using cellRendererParams for actual value display
  cellRendererParams: (params) => ({
    paramsPhoneNumber: params.data._metadata?.callerPhoneNumber ?? params.data.id,
    textStyle: {
      fontWeight: "bold"
    }
  }),
  getQuickFilterText: (params) =>
    params.data._metadata?.callerPhoneNumber?.formatNational?.().replace(/\D/g, "") ??
    params.data.id,
  // Using value getter for filter values + csv export, since there is no dedicated value getter for csv
  valueGetter: (params) =>
    params.data._metadata?.callerPhoneNumber?.formatInternational?.() ?? params.data.id
};

export const callerFirstNameCol: AgGridColumnProps = {
  field: "callerFirstName",
  headerName: "שם פרטי",
  filter: true,
  filterParams: StringFilterParams,
  editable: true
};

export const callerLastNameCol: AgGridColumnProps = {
  field: "callerLastName",
  headerName: "שם משפחה",
  filter: true,
  filterParams: StringFilterParams,
  editable: true
};

export const createdTimeCol: AgGridColumnProps = {
  field: "createdTime",
  headerName: "תאריך יצירה",
  filter: "agDateColumnFilter",
  filterParams: DateFilterParams,
  cellRenderer: "DateTimeRenderer"
};

export const updatedTimeCol: AgGridColumnProps = {
  field: "updatedTime",
  headerName: "תאריך עדכון אחרון",
  filter: "agDateColumnFilter",
  filterParams: DateFilterParams,
  cellRenderer: "DateTimeRenderer"
};

export const contactOriginCol: AgGridColumnProps = {
  headerName: "מקור ליד",
  valueGetter: (params) => {
    if (params.data.contactOrigin && params.data.contactOriginAdditionalInfo) {
      return params.data.contactOrigin + ` (${params.data.contactOriginAdditionalInfo})`;
    }

    return params.data.contactOrigin ?? params.data.contactOriginAdditionalInfo ?? "";
  },
  minWidth: 350,
  filter: true,
  filterParams: StringFilterParams
};

export const lastBulkMessageReceivedTimeCol: AgGridColumnProps = {
  field: "lastBulkMessageReceived.sentTime",
  headerName: "הודעה אחרונה התקבלה:",
  //filter: "agDateColumnFilter",
  filter: "lastBulkMessageReceivedFilter",
  filterParams: DateFilterParams,
  cellRenderer: "DateTimeRenderer"
};

export const optedOutOfBulkMessagesCol: AgGridColumnProps = {
  field: "optedOutOfBulkMessages",
  headerName: "ביקש לא לקבל דיוור",
  cellEditor: "agSelectCellEditor",
  cellEditorParams: {
    values: optedOutOfBulkMessagesPossibleValues
  },
  valueFormatter: (params) => typeof params.value === "boolean" && (params.value ? "כן" : "לא"),
  cellRenderer: "BooleanRenderer",
  cellRendererParams: () => ({
    colors: { true: "error", false: "success" }
  }),
  editable: true
};

export const isInSpamDatabaseCol: AgGridColumnProps = {
  // field: "isInSpamDatabase",
  headerName: "נמצא במאגר 'לא להתקשר אליי'",
  cellEditor: "agSelectCellEditor",
  cellEditorParams: {
    values: isInSpamDatabasePossibleValues
  },
  valueGetter: (params) => {
    if (params.data.isInSpamDatabase === true) return "כן";
    if (params.data.isInSpamDatabase === false) return "לא";
    return "?";
  },
  cellRenderer: "ColoredLabelRenderer",
  cellRendererParams: {
    colors: {
      לא: "success",
      כן: "error",
      "?": "warning"
    }
  },
  editable: false
};

export const additionalNotesCol: AgGridColumnProps = {
  field: "additionalNotes",
  headerName: "הערות נוספות",
  filter: true,
  filterParams: StringFilterParams,
  editable: true
};

export const generiCrmColumns: AgGridColumnProps[] = [
  metadataParsedCallerPhoneNumberCol,
  callerFirstNameCol,
  callerLastNameCol,
  additionalNotesCol,
  createdTimeCol,
  updatedTimeCol,
  contactOriginCol,
  lastBulkMessageReceivedTimeCol,
  optedOutOfBulkMessagesCol,
  isInSpamDatabaseCol
];

export const virtualNumberCallerNumberCol: AgGridColumnProps = {
  field: "callerNumber",
  headerName: "מספר נכנס",
  cellStyle: { "font-weight": "bold" },
  maxWidth: 150,
  filter: true,
  filterParams: StringFilterParams
};
export const virtualNumberCallDurationCol: AgGridColumnProps = {
  headerName: "משך שיחה",
  comparator: NumberComparator,
  valueGetter: (params) => {
    let row = params.data;
    let answeredCall = row["result"] === "ANSWER";
    return answeredCall ? row["duration"] : null;
  },
  valueFormatter: (params) =>
    !params.value ? "" : moment.duration(params.value, "seconds").format()
};
export const virtualNumberCallEndAtCol: AgGridColumnProps = {
  field: "endAt",
  headerName: "תאריך שיחה",
  filter: "agDateColumnFilter",
  filterParams: DateFilterParams,
  cellRenderer: "DateTimeRenderer"
};

export const virtualNumberCallResultCol: AgGridColumnProps = {
  field: "result",
  headerName: "",
  cellRenderer: "CallStatusRenderer",
  maxWidth: 80
};

export const virtualNumberCallRecordingLinkDirectCol: AgGridColumnProps = {
  valueGetter: (params) => {
    let row = params.data as CallSummary;
    const audioUrl = getAudioUrlFromCallSummary(row);
    return audioUrl;
  },
  headerName: "הקלטה",
  cellRenderer: "AudioPlayerCellRenderer",
  minWidth: 300
};

export const virtualNumberPhoneNumberCol: AgGridColumnProps = {
  field: "virtualNumber",
  headerName: "מספר וירטואלי",
  cellStyle: { "font-weight": "bold" },
  maxWidth: 150,
  filter: true,
  filterParams: StringFilterParams
};

export const virtualNumberCallsColumns: AgGridColumnProps[] = [
  virtualNumberCallResultCol,
  metadataParsedCallerPhoneNumberCol,
  virtualNumberCallDurationCol,
  virtualNumberCallEndAtCol,
  virtualNumberCallRecordingLinkDirectCol
];

export const metadataColumnNames: string[] = [
  createdTimeCol.field,
  contactOriginCol.headerName,
  lastBulkMessageReceivedTimeCol.field
];

import { db } from "firebaseConfig";
import { realEstateContactConverter } from "utils/firestoreConverters";
import firebase from "firebase/compat";
import type { RealEstateContact } from "../.../../../@types/sharedSchema";

export function getUpdatedContactsQuery(
  email: string
): firebase.firestore.Query<RealEstateContact> {
  const currentDate = new Date();

  return db
    .collection(`/dashboard-users-v2/${email}/contacts`)
    .where("updatedTime", ">", currentDate)
    .orderBy("updatedTime", "desc")
    .withConverter(realEstateContactConverter)
    .limit(100);
}

export function parseCustomerBudget(
  customerBudget: number | string | undefined
): number | string | undefined {
  if (customerBudget === undefined) return;

  if (typeof customerBudget === "string") {
    const parsedCustomerBudget = parseInt(customerBudget.replace(/,/g, ""));

    if (!isNaN(parsedCustomerBudget)) return parsedCustomerBudget;
  }

  return customerBudget;
}

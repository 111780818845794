import { he } from "date-fns/locale";
import { format, getTime, formatDistanceToNow } from "date-fns";

// ----------------------------------------------------------------------

export function fDate(date: Date | string | number, rtl: boolean = false) {
  return format(new Date(date), "dd MMM yyyy", {
    locale: rtl ? he : undefined
  });
}

export function fDateTime(date: Date | string | number, rtl: boolean = false) {
  return format(new Date(date), "dd MMM yyyy pp", {
    locale: rtl ? he : undefined
  });
}

export function fTime(date: Date | string | number, rtl: boolean = false) {
  return format(new Date(date), "pp", {
    locale: rtl ? he : undefined
  });
}

export function fTimestamp(date: Date | string | number, rtl: boolean = false) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number, rtl: boolean = false) {
  return format(new Date(date), "dd/MM/yyyy hh:mm p", {
    locale: rtl ? he : undefined
  });
}

export function fToNow(date: Date | string | number, rtl: boolean = false) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
    locale: rtl ? he : undefined
  });
}
